export enum NotificationsActions {
  contactCreated = 'CONTACT_CREATED',
  contactUpdate = 'CONTACT_UPDATED',
  documentUploaded = 'DOCUMENT_UPLOADED',
  newMessage = 'MESSAGE_CREATED',
  downloadReady = 'DOWNLOAD_READY',
  quoteCreated = 'QUOTE_CREATED',
  quoteEdited = 'QUOTE_UPDATED',
  quoteExtended = 'QUOTE_EXTENDED',
  quoteCanceled = 'QUOTE_CANCELED',
  quoteAccepted = 'QUOTE_ACCEPTED',
  quoteRejected = 'QUOTE_REJECTED',
  labelCreated = 'LABEL_CREATED',
  labelRemoved = 'LABEL_REMOVED',
  labelUpdated = 'LABEL_UPDATED',
  userAddedToPortal = 'USER_ADDED' //BrandPortal: user accepted invitation

  // TODO:add here all other types of actions and pass to the switches

  // closed contact request
  // canceled contact request
}

export const isBrandPortalAction = (action: NotificationsActions) =>
  action === NotificationsActions.labelCreated ||
  action === NotificationsActions.labelRemoved ||
  action === NotificationsActions.labelUpdated ||
  action === NotificationsActions.userAddedToPortal;
