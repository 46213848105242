import { Company } from '../types/Company';
import PropTypes from 'prop-types';
import { request } from '../api';
import { request as requestTs } from '../apiTS';

export const baseApi = process.env.REACT_APP_COVALO_API;
export const baseApiSearch = process.env.REACT_APP_COVALO_API_SEARCH;

export const findCompany = (companyKey, success, handleError) => {
  const options = {
    method: 'GET',
    url: `${baseApiSearch}/search/companies/${companyKey}`,
    headers: { 'Content-Type': 'application/json' }
  };

  request(options, success, handleError);
};

/**
 * Please consider that this returns only the first page.
 * In most use cases this is OK because a user belongs either to few or to all companies.
 */
export const getAllCompanies = (success, handleError, companiesKeys) => {
  const searchParams = new URLSearchParams();
  if (companiesKeys) {
    searchParams.set('key', companiesKeys?.join(','));
  }
  const url = `${baseApi}/companies${
    searchParams.toString() ? `?${searchParams.toString()}` : ''
  }`;

  const options = {
    method: 'GET',
    url: url,
    headers: { 'Content-Type': 'application/json' }
  };

  requestTs(options, success, handleError);
};

export const findCompanyEvent = (
  eventKey,
  companyKey,
  success,
  handleError
) => {
  const options = {
    method: 'GET',
    url: `${baseApiSearch}/search/${eventKey}/companies/${companyKey}`,
    headers: { 'Content-Type': 'application/json' }
  };

  request(options, success, handleError);
};

export const updateCompanyName = (key, data, success, handleError) => {
  const options = {
    method: 'POST',
    url: `${baseApi}/companies/${key}/name`,
    headers: { 'Content-Type': 'application/json' },
    data: data
  };

  request(options, success, handleError);
};

export const updateCompanyPermissions = (
  company,
  permissions,
  success,
  handleError
) => {
  const options = {
    method: 'PUT',
    url: `${baseApi}/companies/${company.key}/permissions`,
    headers: { 'Content-Type': 'application/json' },
    data: permissions
  };

  request(options, success, handleError);
};

export const createCompany = (company, success, handleError) => {
  const options = {
    method: 'POST',
    url: `${baseApi}/companies`,
    headers: { 'Content-Type': 'application/json' },
    data: company
  };

  request(options, success, handleError);
};

export const updateCompany = (key, company, success, handleError) => {
  const options = {
    method: 'PUT',
    url: `${baseApi}/companies/${key}`,
    headers: { 'Content-Type': 'application/json' },
    data: company
  };

  request(options, success, handleError);
};

export const filterCompanyTable = (value, search, success, handleError) => {
  let encoded = encodeURIComponent(value);
  const defaultParams =
    'operator=OR&fields=name,type,offering,segments,verified,enabled,key';
  const filters = `&type__icontains=${encoded}&name__icontains=${encoded}`;
  let sort = '';
  search.sort.map(item => {
    sort = `${sort}&sort=${item.id},${item.desc ? 'desc' : 'asc'}`;
  });
  const options = {
    method: 'GET',
    url: `${baseApi}/companies?${defaultParams}${filters}${sort}`,
    headers: { 'Content-Type': 'application/json' },
    params: {
      size: search.size,
      page: search.page
    }
  };

  request(options, success, handleError);
};

export const findByKey = (key, success, handleError) => {
  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${key}`,
    headers: { 'Content-Type': 'application/json' }
  };

  request(options, success, handleError);
};

export const updateCompanyTopProducts = (
  company,
  products,
  success,
  handleError
) => {
  parseTopProducts(products.products);

  const options = {
    method: 'PUT',
    url: `${baseApi}/companies/${company.key}/top-products`,
    headers: { 'Content-Type': 'application/json' },
    data: products
  };

  request(options, success, handleError);
};
function parse(products, type, segment) {
  if (products && products[type] && products[type][segment]) {
    let options = [];
    products[type][segment].forEach((item, index) => {
      item['key'] = item.value;
      item['name'] = item.label;
    });
  }
}

export const parseTopProducts = topProducts => {
  parse(topProducts, 'INGREDIENTS', 'PERSONALCARE');
  parse(topProducts, 'INGREDIENTS', 'HOMECARE');
  parse(topProducts, 'INGREDIENTS', 'FOOD');
  parse(topProducts, 'FORMULATIONS', 'PERSONALCARE');
  parse(topProducts, 'FORMULATIONS', 'HOMECARE');
  parse(topProducts, 'SERVICES', 'PERSONALCARE');
  parse(topProducts, 'SERVICES', 'HOMECARE');
};

export const findTopProducts = (key, success, handleError) => {
  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${key}/top-products`,
    headers: { 'Content-Type': 'application/json' }
  };

  request(
    options,
    response => {
      success(renderTopProductsOptions(response.data.products));
    },
    handleError
  );
};

export function renderTopProductsOptions(products) {
  return {
    // to reset controlled wrapped fields we need to declared them
    products: {
      INGREDIENTS: {
        PERSONALCARE: parseCategory(products, 'INGREDIENTS', 'PERSONALCARE'),
        HOMECARE: parseCategory(products, 'INGREDIENTS', 'HOMECARE'),
        FOOD: parseCategory(products, 'INGREDIENTS', 'FOOD')
      },
      FORMULATIONS: {
        PERSONALCARE: parseCategory(products, 'FORMULATIONS', 'PERSONALCARE'),
        HOMECARE: parseCategory(products, 'FORMULATIONS', 'HOMECARE')
      },
      SERVICES: {
        PERSONALCARE: parseCategory(products, 'SERVICES', 'PERSONALCARE'),
        HOMECARE: parseCategory(products, 'SERVICES', 'HOMECARE')
      }
    }
  };
}

function parseCategory(products, type, segment) {
  let options = [];
  if (products && products[type] && products[type][segment]) {
    products[type][segment].forEach(item => {
      options.push({
        value: item.key,
        label: item.name
      });
    });
  }
  return options;
}
export const findAllUnRestrictedCompanyOptions = (
  search,
  success,
  handleError
) => {
  let encoded = encodeURIComponent(search);
  const defaultParams = 'operator=OR&fields=name,key&sort=name,asc';
  const filters = `&name__icontains=${encoded}&key__icontains=${encoded}`;
  const options = {
    method: 'GET',
    url: `${baseApi}/companies/invitable?${defaultParams}${filters}`,
    headers: { 'Content-Type': 'application/json' },
    params: {
      size: 15,
      page: 0
    }
  };

  request(options, success, handleError);
};

export const findCompanyOptions = (search, page = 0, success, handleError) => {
  let encoded = encodeURIComponent(search);
  const defaultParams = 'operator=OR&fields=name,key&sort=name,asc';
  const filters = `&name__icontains=${encoded}&key__icontains=${encoded}`;
  const options = {
    method: 'GET',
    url: `${baseApi}/companies?${defaultParams}${filters}`,
    headers: { 'Content-Type': 'application/json' },
    params: {
      size: 15,
      page: page
    }
  };

  request(options, success, handleError);
};

export const findCompanyProducts = (
  search,
  company,
  productType,
  segment,
  success,
  handleError
) => {
  let encoded = encodeURIComponent(search);
  const defaultParams = 'fields=key,name&sort=name,asc';
  const filters = `&name__icontains=${encoded}&segment__eq=${segment}`;
  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${company.key}/products/types/${productType}?${defaultParams}${filters}`,
    headers: { 'Content-Type': 'application/json' },
    params: {
      size: 25,
      page: 0
    }
  };

  request(options, success, handleError);
};

export const filterUsersTable = (
  company,
  value,
  search,
  success,
  handleError
) => {
  let encoded = encodeURIComponent(value);
  const defaultParams = 'operator=OR';
  const filters = `&email__icontains=${encoded}`;
  //const filters = `&email__icontains=${value}&firstname_icontains=${value}&lastname_icontains=${value}`;
  let sort = '';
  search.sort.map(item => {
    sort = `${sort}&sort=${item.id},${item.desc ? 'desc' : 'asc'}`;
  });
  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${company}/users?${defaultParams}${filters}${sort}`,
    headers: { 'Content-Type': 'application/json' },
    params: {
      size: search.size,
      page: search.page
    }
  };

  request(options, success, handleError);
};

export const grantRoles = (company, user, roles, success, handleError) => {
  const options = {
    method: 'POST',
    url: `${baseApi}/companies/${company}/users/${user}/grant`,
    headers: { 'Content-Type': 'application/json' },
    data: {
      roles
    }
  };
  request(options, success, handleError);
};

export const revokeRole = (company, user, roles, success, handleError) => {
  const options = {
    method: 'POST',
    url: `${baseApi}/companies/${company}/users/${user}/revoke`,
    headers: { 'Content-Type': 'application/json' },
    data: {
      roles
    }
  };
  request(options, success, handleError);
};

export const revokeAllRoles = (company, user, success, handleError) => {
  const options = {
    method: 'POST',
    url: `${baseApi}/companies/${company}/users/${user}/revoke-all`,
    headers: { 'Content-Type': 'application/json' }
  };
  request(options, success, handleError);
};

export const loadAllCompanyContacts = (company, success, handleError) => {
  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${company}/contacts`,
    headers: { 'Content-Type': 'application/json' }
  };

  request(options, success, handleError);
};

export const createCompanyContact = (
  company,
  contact,
  edit,
  success,
  handleError
) => {
  const method = edit ? 'PUT' : 'POST';
  const urlSuffix = edit ? `/${contact.key}` : '';

  const options = {
    method: method,
    url: `${baseApi}/companies/${company.key}/contacts${urlSuffix}`,
    headers: { 'Content-Type': 'application/json' },
    data: contact
  };

  request(options, success, handleError);
};

export const filterCompanyUsersOptions = (
  company,
  value,
  success,
  handleError
) => {
  let encoded = encodeURIComponent(value);
  const defaultParams = 'operator=OR&fields=firstname,lastname,email';
  const filters = `&firstname__icontains=${encoded}&lastname__icontains=${encoded}&email__icontains=${encoded}`;
  let sort = '&sort=firstname,asc&sort=lastname,desc';

  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${company.key}/contacts/users/available?${defaultParams}${filters}${sort}`,
    headers: { 'Content-Type': 'application/json' },
    params: {
      size: 15,
      page: 0
    }
  };

  request(
    options,
    response => {
      const users = [];
      response.data.content.forEach(item => {
        users.push({
          value: item.key,
          label: item.firstname + ' ' + item.lastname + ' (' + item.email + ')'
        });
      });
      success(users);
    },
    handleError
  );
};

export const deleteCompanyContact = (
  company,
  contact,
  success,
  handleError
) => {
  const options = {
    method: 'DELETE',
    url: `${baseApi}/companies/${company.key}/contacts/${contact.key}`,
    headers: { 'Content-Type': 'application/json' },
    data: contact
  };

  request(options, success, handleError);
};

export const verifyCompany = (companyKey, verify, handleError) => {
  const options = {
    method: verify ? 'POST' : 'DELETE',
    url: `${baseApi}/companies/${companyKey}/verify`,
    headers: { 'Content-Type': 'application/json' }
  };

  request(options, handleError);
};

export const enableCompany = (companyKey, verify, handleError) => {
  const options = {
    method: 'POST',
    url: `${baseApi}/companies/${companyKey}/${verify ? 'enable' : 'disable'}`,
    headers: { 'Content-Type': 'application/json' }
  };

  request(options, handleError);
};

//TODO change following methods

export const loadCompanyAssets = (key, success, handleError) => {
  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${key}/assets`,
    headers: { 'Content-Type': 'application/json' }
  };

  request(options, success, handleError);
};

export const downloadCompanyAsset = (key, asset, success, handleError) => {
  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${key}/assets/${asset.key}/download`,
    responseType: 'blob'
  };

  request(options, success, handleError);
};

export const deleteCompanyAsset = (key, asset, success, handleError) => {
  const options = {
    method: 'DELETE',
    url: `${baseApi}/companies/${key}/assets/${asset.key}`
  };

  request(options, success, handleError);
};

export const uploadCompanyAsset = (key, asset, file, success, handleError) => {
  let data = new FormData();
  data.append(
    'asset',
    new Blob([JSON.stringify(asset)], { type: 'application/json' })
  );
  data.append('content', file);

  const options = {
    method: 'POST',
    url: `${baseApi}/companies/${key}/assets`,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: data
  };
  request(options, success, handleError);
};

filterCompanyTable.propTypes = {
  value: PropTypes.string
};

createCompany.propTypes = {
  company: PropTypes.objectOf(Company).isRequired,
  success: PropTypes.func.isRequired
};
