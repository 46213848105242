import { Helmet } from 'react-helmet';
import { tss } from 'tss-react/mui';
import { SimpleSearchBar } from '../../common/SimpleSearchBar';
import { NotFoundLogo } from './NotFoundLogo';
import { Typography, useTheme } from '@mui/material';

export const NotFoundPage = () => {
  const { classes } = useStyles();
  const mainColor = useTheme().palette.primary.main;
  return (
    <>
      <Helmet>
        <title>Covalo | Page not found</title>
      </Helmet>
      <div className={classes.root}>
        <NotFoundLogo className={classes.logo} color={mainColor} />
        <Typography
          variant="h2"
          component={'h1'}
          sx={{
            textAlign: 'center'
          }}
        >
          Page not found
        </Typography>
        <Typography
          variant="h6"
          component={'h2'}
          sx={{
            textAlign: 'center'
          }}
        >
          Sorry, the page you were looking for doesn't exist or has been moved.
        </Typography>
        <div className="my-3 search-bar-input-wrapper w-100">
          <SimpleSearchBar placeholder={'Search for similar products ...'} />
        </div>
      </div>
    </>
  );
};

const useStyles = tss.create(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '10%'
  },
  logo: {
    width: '150px',
    marginBottom: '24px',
    cursor: 'default !important'
  }
}));
