import { request } from '../api';
const baseApi = process.env.REACT_APP_COVALO_API;

export const getNotifications = (notificationsParams, success, handleError) => {
  const options = {
    method: 'GET',
    url: `${baseApi}/notifications${notificationsParams}`,
    headers: { 'Content-Type': 'application/json' }
  };
  request(options, success, handleError);
};

export const readAllNotifications = (success, handleError) => {
  const options = {
    method: 'POST',
    url: `${baseApi}/notifications/read-all`,
    headers: { 'Content-Type': 'application/json' }
  };
  request(options, success, handleError);
};

export const readNotification = (id, success, handleError) => {
  const options = {
    method: 'POST',
    url: `${baseApi}/notifications/${id}/read`,
    headers: { 'Content-Type': 'application/json' }
  };

  request(options, success, handleError);
};
