import {
  LegacyPermission,
  hasAccess,
  hasLegacyPermission,
  isSupport
} from 'helpers/Roles';
import { BrandPortalPermissions, EventPermissionLevel } from 'portals/types';
import { LoggedInUser } from 'types/User';

export const checkPrivateRoutePermissions = (
  user: LoggedInUser,
  allowedUsers?: string[],
  excludeRoles?: string[],
  legacyPermissions?: LegacyPermission[],
  brandPortalPermission?: BrandPortalPermissions[],
  eventKey?: string
) => {
  const event = user?.brandPortalEvent?.find(event => event.key === eventKey);
  if (isSupport(user)) return true;
  if (!user.roles) return false;
  if (brandPortalPermission && brandPortalPermission.length > 0) {
    return brandPortalPermission.some(permission => {
      if (permission === BrandPortalPermissions.SEARCH) {
        return event?.permissions?.[permission] === EventPermissionLevel.VIEW;
      } else {
        return event?.permissions?.[permission] === EventPermissionLevel.MANAGE;
      }
    });
  }
  if (legacyPermissions && legacyPermissions.length > 0) {
    return (
      hasLegacyPermission(legacyPermissions, user) &&
      hasAccess(allowedUsers, excludeRoles, user)
    );
  } else {
    return hasAccess(allowedUsers, excludeRoles, user);
  }
};
