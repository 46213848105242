import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import {
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Zoom
} from '@mui/material';
import { UserStateContextType } from 'components/constants/Constants';
import { useUserState } from 'context/User';
import React from 'react';
import { useAuth } from 'react-oidc-context';
import { Link } from 'react-router-dom';
export const UserDropDown = () => {
  const auth = useAuth();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navOpen = Boolean(anchorElNav);
  const userState = useUserState();
  return userState.type === UserStateContextType.AUTHENTICATED ? (
    <>
      <IconButton onClick={handleOpenNavMenu}>
        <AccountCircleOutlinedIcon />
      </IconButton>

      {anchorElNav && (
        <Menu
          anchorEl={anchorElNav}
          open={navOpen}
          onClose={handleCloseNavMenu}
          MenuListProps={{
            dense: true
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: -12,
            horizontal: 'left'
          }}
          TransitionComponent={Zoom}
        >
          <MenuItem
            disabled
            sx={{
              '&.Mui-disabled': {
                opacity: 1
              }
            }}
          >
            <ListItemText
              primary={`Hi ${userState.user?.firstName} ${
                userState.user?.lastName ?? ''
              }!`}
              secondary={userState.user?.username}
            />
          </MenuItem>

          <Divider />
          <MenuItem
            component={Link}
            to={'/user/profile'}
            onClick={handleCloseNavMenu}
          >
            <ListItemIcon>
              <ManageAccountsOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>My Profile</ListItemText>
          </MenuItem>
          <MenuItem
            key={'register'}
            onClick={() => {
              auth.signoutSilent();
            }}
          >
            <ListItemIcon>
              <PowerSettingsNewOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Log out</ListItemText>
          </MenuItem>
        </Menu>
      )}
    </>
  ) : (
    <Button
      onClick={() =>
        auth.signinRedirect({
          redirect_uri: window.location.origin + window.location.pathname
        })
      }
    >
      Sign In
    </Button>
  );
};
