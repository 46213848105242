import * as Sentry from '@sentry/react';

export const initSentry = () => {
  try {
    if (process.env.REACT_APP_SENTRY_ENVIRONMENT) {
      Sentry.init({
        dsn: 'https://1884ca96f1090cc81520d39c05e81b04@o4505997096189952.ingest.sentry.io/4505997098942464',
        environment: process.env.REACT_APP_SENTRY_ENVIRONMENT
      });
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Sentry init failed', e);
  }
};
