import Search from '@mui/icons-material/Search';
import { useState } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export const SimpleSearchBar: React.FC<{ placeholder: string }> = props => {
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const navigateTo = () => {
    navigate(`/ingredients/search?query=${search}`);
  };

  return (
    <InputGroup
      style={{
        height: '51px',
        width: '100%',
        maxWidth: '540px',
        margin: 'auto'
      }}
      className={'position-relative '}
    >
      <FormControl
        onKeyDown={event => {
          if (event.key === 'Enter') {
            navigateTo();
          }
        }}
        onChange={({ target: { value } }) => setSearch(value)}
        id="search"
        placeholder={props.placeholder}
        type="search"
        className="shadow-none search-border-start"
      />
      <InputGroup.Text
        className="bg-white"
        style={{ cursor: 'pointer' }}
        onClick={() => navigateTo()}
      >
        <Search fontSize="small" />
      </InputGroup.Text>
    </InputGroup>
  );
};
