import { useEffect } from 'react';

/**
 * Used for navigation when a hard reload is required (like for the 404 page when we need the correct status code).
 */
export const NavigateWithoutRouter: React.FC<{ to: string }> = props => {
  useEffect(() => {
    window.location.assign(props.to);
  }, [props.to]);
  return null;
};
