import AllInbox from '@mui/icons-material/AllInbox';
import { Typography } from '@mui/material';

export const NoNotifications = ({ message }) => {
  return (
    <div className="my-5 d-flex flex-column align-items-center justify-content-center gap-3">
      <AllInbox fontSize="large" />
      <Typography>
        {message ? message : 'No notifications to display'}
      </Typography>
    </div>
  );
};
