import PiwikReactRouter from 'piwik-react-router';

let host = 'test.covalo.com';
if (window.location.host !== 'localhost:3000') {
  host = window.location.host;
}
const config = {
  REACT_APP_MATOMO_URL: 'https://analytics.' + host
};
let piwik = {
  push: function (a: string[]) {
    // do nothing
  },
  connectToHistory: function (a: any) {
    // do nothing
  },
  trackLink: function (url: string) {
    // do nothing
  }
};
// @ts-ignore
piwik = new PiwikReactRouter({
  url: config.REACT_APP_MATOMO_URL,
  siteId: '1',
  trackErrors: true,
  enableLinkTracking: true
});

piwik.trackLink = url => {
  piwik.push(['trackLink', url, 'link']);
};

export const trackSearchEvent = (
  action: string | undefined,
  message: string
) => {
  if (!action) {
    return;
  }
  trackEvent('Search', action, message);
};

const trackEvent = (type: string, action: string, message: string) => {
  piwik.push(['trackEvent', type, action, message]);
  piwik.push(['trackGoal', '1']);
};

export default piwik;
