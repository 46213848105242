import { LDProvider, basicLogger } from 'launchdarkly-react-client-sdk';

export const LaunchDarklyProvider: React.FC<React.PropsWithChildren> = ({
  children
}) => {
  return (
    <LDProvider
      clientSideID={process.env.REACT_APP_LAUNCH_DARKLY_ID ?? ''}
      context={{
        kind: 'user',
        anonymous: true,
        // We use a shared key to keep the MAU under 10k.
        // https://docs.launchdarkly.com/home/contexts/anonymous-contexts#using-a-shared-key-between-anonymous-contexts
        key: 'anonymous-shared-key'
      }}
      options={{
        logger: basicLogger({ level: 'error' })
      }}
    >
      {children}
    </LDProvider>
  );
};
