import { makeUseAxios } from 'axios-hooks';
import axios from 'axios';
import { getUser } from 'auth/AuthenticationProvider';

const axiosWithAuth = axios.create();

export const useAxiosWithAuth = makeUseAxios({
  axios: axiosWithAuth
});

axiosWithAuth.interceptors.request.use(
  async config => {
    const token = getUser()?.access_token;

    if (token) {
      config.headers = {
        authorization: `Bearer ${token}`
      };
    }
    return config;
  },
  error => Promise.reject(error)
);
