import { UserStateContextType } from 'components/constants/Constants';
import { useUserState } from 'context/User';
import allRoles, {
  LegacyPermission,
  hasAccess,
  hasLegacyPermission,
  hasRole,
  isSupport
} from 'helpers/Roles';
import {
  BrandPortalPermissions,
  EventPermissionLevel,
  PortalTypes
} from 'portals/types';

export const useNavigationBarRoles = () => {
  const userState = useUserState();

  if (userState.type !== UserStateContextType.AUTHENTICATED)
    return {
      isBrandPortalAdmin: false,
      canInviteUsers: false,
      canEditProductsAndCompany: false,
      hasAnalyticsPermissions: false,
      hasLeadsPermissions: false,
      hasProjectSpacePermissions: false,
      hasEventsSectionPermissions: false,
      brandPortalRoutes: [],
      isSupportUser: false
    };

  return {
    isBrandPortalAdmin: userState.user?.brandPortalEvent?.some(
      event =>
        event.type === PortalTypes.BRAND_PORTAL &&
        Object.values(BrandPortalPermissions).some(
          permission =>
            event.permissions?.[permission] === EventPermissionLevel.MANAGE
        )
    ),
    canInviteUsers:
      hasRole(allRoles.EDIT_COMPANY, userState.user) ||
      /* 
  In the backend, all Harmona users are allowed to see all the companies. 
  This requirement was specified by CSM at https://covalo.slack.com/archives/C02MA1ND33P/p1713248864580339. 
  The purpose is to allow info@covalo.com to view all companies to facilitate user invitations during demo using this account. 
  Since info@covalo.com is a Harmona user in production, this setup works.  
  Ideally, we should check for Harmona users here, but it's not straightforward as we lack the Harmona company key for all environments. 
  For now, let's keep the current implementation with this comment for clarity. 
*/
      userState.user?.username === 'info@covalo.com',
    canEditProductsAndCompany: hasAccess(
      [...allRoles.EDIT_COMPANY, ...allRoles.EDIT_PRODUCTS],
      [],
      userState.user
    ),
    hasAnalyticsPermissions:
      hasLegacyPermission([LegacyPermission.ANALYTICS_SHOW], userState.user) &&
      hasAccess(
        [...allRoles.SUPPORT_ROLES, 'supplier-manager'],
        [],
        userState.user
      ),

    hasLeadsPermissions:
      hasLegacyPermission([LegacyPermission.LEADS_SHOW], userState.user) &&
      hasAccess(
        [
          ...allRoles.SUPPORT_ROLES,
          'supplier-manager',
          'supplier-manager-unverified'
        ],
        [],
        userState.user
      ),
    hasProjectSpacePermissions: hasAccess(
      allRoles.PROJECT_SPACE,
      allRoles.SUPPLIER_ROLES,
      userState.user
    ),
    hasEventsSectionPermissions: hasAccess(
      allRoles.SUPPORT_ROLES,
      [],
      userState.user
    ),
    isSupportUser: isSupport(userState.user),
    brandPortalRoutes:
      userState.user.brandPortalEvent
        ?.filter(
          event =>
            event.key &&
            event.type === PortalTypes.BRAND_PORTAL &&
            event.permissions?.[BrandPortalPermissions.SEARCH] ===
              EventPermissionLevel.VIEW
        )
        .map(event => ({
          label: event.name,
          key: event.key
        })) ?? []
  };
};
