import { getUser } from 'auth/AuthenticationProvider';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ToastPromiseParams, toast } from 'react-toastify';
import { captureMessage } from '@sentry/react';

export type SuccessHandler<T = any, D = any> = (
  response: AxiosResponse<T, D>
) => void;
export type ErrorHandler = (error: any) => void;
export type Options = AxiosRequestConfig;

export const requestUnauthenticated = <T = any, D = any>(
  options: Options,
  success: SuccessHandler<T, D>,
  handleError: ErrorHandler
) => {
  axios
    .request(options)
    .then(function (response) {
      success(response);
    })
    .catch(function (error) {
      if (handleError) {
        handleError(error);
      }
    });
};

export const request = <T, D>(
  options: Options,
  success: SuccessHandler<T, D>,
  handleError?: ErrorHandler,
  /**
   * When this object is only partly defined, there might be strange behaviour.
   * For example when error is not defined, the default error message will show up, but
   * be overridden after a second by an empty error message and therefore disappear.
   */
  toastPromiseParams?: ToastPromiseParams,
  customErrorMessage = false,
  suppressSentryErrorOnFailure = false
) => {
  if (!options['headers']) {
    options['headers'] = {};
  }
  const token = getUser()?.access_token;
  if (token) {
    options['headers']['Authorization'] = `Bearer ${token}`;
  }

  // for debugging https://covalo.sentry.io/issues/4558735027/events/67ae604898374e0b99eb5b68ce85c775/
  if (!success) {
    captureMessage('apiTS: success function is not defined', {
      tags: {
        requestUrl: options.url
      },
      level: 'error'
    });
  }

  const promise = () =>
    new Promise<void>((resolve, reject) =>
      axios.request(options).then(
        function (response) {
          success(response);
          resolve();
        },
        function (error: AxiosError) {
          if (
            toastPromiseParams &&
            !toastPromiseParams?.error &&
            !customErrorMessage
          ) {
            toast.error(
              //@ts-ignore we have this structure in some responses
              error?.response?.data?.detail ??
                error?.message ??
                'Something went wrong'
            );
          }
          if (handleError) {
            handleError(error);
          }

          if (!suppressSentryErrorOnFailure) {
            captureMessage('Network request failed...', {
              tags: {
                errorCode: error.code,
                statusCode: error.response?.status,
                requestUrl: error.config?.url,
                originFile: 'apiTS'
              },
              level: 'warning'
            });
          }
          reject(error);
        }
      )
    );

  return (
    toastPromiseParams ? toast.promise(promise, toastPromiseParams) : promise()
  ).catch(() => {
    // We don't want to have unhandled promise rejection errors in the console.
    // It's possible to handle the error in a user friendly way via handleError.
  });
};

export const requestRedirectDownload = (
  options: { url: string; headers: any },
  handleError: ErrorHandler
) => {
  if (!options['headers']) {
    options['headers'] = {};
  }
  options['headers']['Authorization'] = `Bearer ${getUser()?.access_token}`;
  const headers = options.headers;
  fetch(options.url, { headers })
    .then(res => window.open(res.url, '_blank'))
    .catch(function (error) {
      if (handleError) {
        handleError(error);
      }
    });
};
