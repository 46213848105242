import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

export const LoginPage: React.FC = () => {
  const { isLoading, isAuthenticated, signinRedirect } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    } else {
      signinRedirect({
        redirect_uri: `${process.env.REACT_APP_COVALO_FRONTOFFICE}/ingredients/search`
      });
    }
  }, [isAuthenticated, isLoading, navigate, signinRedirect]);

  return null;
};
