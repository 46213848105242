import { captureException } from '@sentry/react';
import { Log, User, WebStorageStateStore } from 'oidc-client-ts';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { ExpiredSessionHelper } from './ExpiredSessionHelper';

Log.setLevel(Log.ERROR);
Log.setLogger({
  ...console,
  error: (...e) => {
    console.error(...e); // eslint-disable-line no-console
    captureException('OIDC error', { originalException: { ...e } });
  }
});

export const OAUTH_CLIENT_ID = process.env.REACT_APP_KEYCLOAK_CLIENT ?? '';
export const OAUTH_AUTHORITY = `${process.env.REACT_APP_KEYCLOAK_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}`;
export const OAUTH_SILENT_REDIRECT_PATHNAME = '/silentRenew';
const OAUTH_SILENT_REDIRECT_URI =
  process.env.REACT_APP_COVALO_FRONTOFFICE + OAUTH_SILENT_REDIRECT_PATHNAME;
export const OAUTH_REDIRECT_URI =
  process.env.REACT_APP_COVALO_FRONTOFFICE ?? '';

const config: AuthProviderProps = {
  client_id: OAUTH_CLIENT_ID,
  authority: OAUTH_AUTHORITY,
  redirect_uri: OAUTH_REDIRECT_URI,
  silent_redirect_uri: OAUTH_SILENT_REDIRECT_URI,

  // Without storing it in the local storage, the session would not be shared between tabs.
  userStore: new WebStorageStateStore({ store: window.localStorage }),

  // https://github.com/authts/react-oidc-context#getting-started describes why we need the following.
  onSigninCallback: () =>
    window.history.replaceState({}, document.title, window.location.pathname),

  /**
   * We have different token TTLs defined in the keycloak server:
   * - dev: 1 minute
   * - test: 5 minutes
   * - prod: 15 minutes
   *
   * The default value for accessTokenExpiringNotificationTimeInSeconds is 60 seconds. That means, if the token
   * TTL is 60 seconds or smaller, the token would be constantly refreshed. It's also described here:
   * https://github.com/authts/react-oidc-context/issues/1028. Therefore we need to decrease
   * this value to be smaller than the token TTL to prevent strange behaviour on dev.
   *
   * In the old keycloak client library, the default time of refreshing a token was 5 seconds before it expired:
   * https://github.com/keycloak/keycloak/blob/main/js/libs/keycloak-js/src/keycloak.js#L621
   *
   * 5 seconds might lead to problems in case the token refresh request takes longer than 5 seconds, therfore we
   * set it a little bit higher.
   */
  accessTokenExpiringNotificationTimeInSeconds: 15
};

export const AuthenticationProvider: React.FC<React.PropsWithChildren> = ({
  children
}) => (
  <AuthProvider {...config}>
    <ExpiredSessionHelper />
    {children}
  </AuthProvider>
);

/**
 * Should be only used in rare places where the AuthContext is not available.
 */
export function getUser() {
  const oidcStorage = localStorage.getItem(
    `oidc.user:${process.env.REACT_APP_KEYCLOAK_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}:${process.env.REACT_APP_KEYCLOAK_CLIENT}`
  );
  if (!oidcStorage) {
    return null;
  }

  const user = User.fromStorageString(oidcStorage);

  if (user.expired) {
    return null;
  }

  return user;
}
