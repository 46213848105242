import { request } from '../api';
import { ErrorHandler, SuccessHandler, request as requestTS } from '../apiTS';
import { Event } from '../../types/Events';
import { Company } from '../../types/Companies';
import { toEntity } from '../../components/pages/companies/helpers/form-helper';
import { EventProduct, ProductType } from '../../types/Products';
import { Asset } from '../../types/Asset';
import { ToastPromiseParams } from 'react-toastify';
import {
  BrandPortalPermissions,
  EventPermissionLevel,
  EventUserType,
  PortalTypes,
  UserStatus
} from 'portals/types';
import { useAxiosWithAuth } from './useAxios';

export const baseApi = process.env.REACT_APP_COVALO_API;
export const findTenants = (
  handleSuccess?: Function,
  handleError?: Function
) => {
  const options = {
    method: 'GET',
    url: `${baseApi}/tenants`,
    headers: { 'Content-Type': 'application/json' }
  };
  request(options, handleSuccess, handleError);
};
export const findEvents = (
  tenant?: string,
  search?: string,
  handleSuccess?: Function,
  handleError?: Function,
  page?: any,
  size?: any
) => {
  const params = page
    ? { size: size || 25, page: page }
    : { size: size || 25, page: 0 };
  const hasFilters = tenant || search;
  const filters =
    (search ? `&name__icontains=${encodeURIComponent(search)}` : '') +
    (tenant ? `&tenant.key__eq=${tenant}` : '');

  const options = {
    method: 'GET',
    url: `${baseApi}/events${hasFilters ? '?operator=AND' : ''}${filters}`,
    headers: { 'Content-Type': 'application/json' },
    params: params
  };
  request(options, handleSuccess, handleError);
};

export const findCompanyEvents = (
  company?: string,
  search?: string,
  //TODO: what are the enum values for type and status ? ask backend
  filter?: { tenant?: string; type?: string; status?: string },
  handleSuccess?: Function,
  handleError?: Function,
  page?: any
) => {
  const params = page ? page : { size: 10, page: 0 };
  const hasFilters = filter?.tenant || filter?.status || filter?.type || search;
  const filters =
    (search ? `&name__icontains=${encodeURIComponent(search)}` : '') +
    (filter?.tenant ? `&tenant.key__eq=${filter?.tenant}` : '') +
    (filter?.type ? `&type__eq=${filter?.type}` : '') +
    (filter?.status ? `&status__eq=${filter?.status}` : '');

  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${company}/events${
      hasFilters ? '?operator=AND' : ''
    }${filters}`,
    headers: { 'Content-Type': 'application/json' },
    params: params
  };
  request(options, handleSuccess, handleError);
};
export const findByKey = (
  key: string,
  handleSuccess?: Function,
  handleError?: Function
) => {
  const options = {
    method: 'GET',
    url: `${baseApi}/events/${key}`,
    headers: { 'Content-Type': 'application/json' }
  };
  request(options, handleSuccess, handleError);
};
export const createEvent = (
  body: Event,
  handleSuccess?: Function,
  handleError?: Function
) => {
  const options = {
    method: 'POST',
    url: `${baseApi}/events`,
    headers: { 'Content-Type': 'application/json' },
    data: body
  };
  request(options, handleSuccess, handleError);
};
export const updateEvent = (
  key: string,
  body: Event,
  handleSuccess?: Function,
  handleError?: Function
) => {
  const toastOptions = {
    pending: 'Saving',
    success: 'Event Saved'
  };
  const options = {
    method: 'PUT',
    url: `${baseApi}/events/${key}`,
    headers: { 'Content-Type': 'application/json' },
    data: body
  };
  request(options, handleSuccess, handleError, toastOptions);
};
export const findEventCompany = (
  event: string,
  company: string,
  handleSuccess?: Function,
  handleError?: Function
) => {
  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${company}/events/${event}`,
    headers: { 'Content-Type': 'application/json' }
  };
  request(options, handleSuccess, handleError);
};
export const updateEventCompany = (
  event: string,
  company: string,
  body: Company,
  handleSuccess?: Function,
  handleError?: Function
) => {
  const options = {
    method: 'PUT',
    url: `${baseApi}/companies/${company}/events/${event}`,
    headers: { 'Content-Type': 'application/json' },
    data: toEntity(body)
  };
  request(options, handleSuccess, handleError);
};

export const loadCompaniesAssigned = (
  event: string,
  filters: any,
  handleSuccess?: Function,
  handleError?: Function
) => {
  const search = filters?.search ?? '';
  const pageSize = filters?.pageSize;
  const pageIndex = filters?.pageIndex ?? '0';
  const statusFilter =
    filters?.statusFilter && filters?.statusFilter !== 'ALL'
      ? filters?.statusFilter
      : '';
  const typeFilter =
    filters?.typeFilter && filters?.typeFilter !== 'ALL'
      ? filters?.typeFilter
      : '';
  const queryParams =
    (search ? `&display_name__icontains=${encodeURIComponent(search)}` : '') +
    (statusFilter ? `&enabled__eq=${statusFilter}` : '') +
    (typeFilter ? `&type__icontains=${typeFilter}` : '');

  const options = {
    method: 'GET',
    url: `${baseApi}/events/${event}/companies?operator=AND${
      queryParams ? queryParams : ''
    }&sort=display_name,asc`,

    headers: { 'Content-Type': 'application/json' },
    params: {
      size: pageSize && pageSize !== '0' ? pageSize : '10',
      page: pageIndex
    }
  };

  request(options, handleSuccess, handleError);
};

export const loadAvailableCompanies = (
  isBrandPortal: boolean, //true for brand portals to get also the disabled ones+filter the companies who just have ingredients
  event: string,
  filters: any,
  handleSuccess?: Function,
  handleError?: Function
) => {
  const search = filters?.search ? encodeURIComponent(filters?.search) : '';
  const pageSize = filters?.pageSize;
  const pageIndex = filters?.pageIndex ?? '0';
  const typeFilter =
    filters?.typeFilter && filters?.typeFilter !== 'ALL'
      ? filters?.typeFilter
      : null;
  const queryParams = filters
    ? `&name__icontains=${search}${
        typeFilter ? `${`&type__icontains=${typeFilter}`}` : ''
      }`
    : '';
  const options = {
    method: 'GET',
    url: `${baseApi}/events/${event}/companies/available?operator=AND${queryParams}&sort=name,asc${
      isBrandPortal
        ? '&include_disabled=true&offering__eq=INGREDIENTS&type__eq=SUPPLIER_OF_RAW_MATERIALS_AND_INGREDIENTS,DISTRIBUTOR'
        : ''
    }`,
    headers: { 'Content-Type': 'application/json' },
    params: {
      size: pageSize && pageSize !== '0' ? pageSize : '10',
      page: pageIndex
    }
  };

  request(options, handleSuccess, handleError);
};

export const createEventProduct = (
  event: string,
  company: string,
  data: EventProduct,
  handleSuccess: Function,
  handleError?: Function
) => {
  const toastOptions = {
    pending: 'Saving...',
    success: 'Product created successfully'
  };
  const options = {
    method: 'POST',
    url: `${baseApi}/companies/${company}/events/${event}/products/create`,
    headers: { 'Content-Type': 'application/json' },
    data: data
  };
  request(options, handleSuccess, handleError, toastOptions);
};

export const loadAssignedProducts = (
  event: string,
  company: string,
  filters: {
    search?: string;
    pageSize?: number;
    pageIndex?: number;
    typeFilter?: string;
    statusFilter?: string;
    promotedFilter?: string;
    segmentFilter?: string;
    keyFilter?: string[];
  },
  handleSuccess: Function,
  handleError?: Function
) => {
  const search = filters?.search ? encodeURIComponent(filters?.search) : '';
  const pageSize = filters?.pageSize ?? 100;
  const pageIndex = filters?.pageIndex ?? '0';
  const typeFilter =
    filters?.typeFilter && filters?.typeFilter !== 'ALL'
      ? filters?.typeFilter
      : null;
  const keyFilter = filters?.keyFilter ?? null;
  const statusFilter =
    filters?.statusFilter && filters?.statusFilter !== 'ALL'
      ? filters?.statusFilter
      : null;
  const promotedFilter =
    filters?.promotedFilter && filters?.promotedFilter !== 'ALL'
      ? filters?.promotedFilter
      : null;

  const queryParams =
    (search ? `&display_name__icontains=${search}` : '') +
    (statusFilter ? `&enabled__eq=${statusFilter}` : '') +
    (promotedFilter ? `&promoted__eq=${promotedFilter}` : '') +
    //whitelabel
    // eslint-disable-next-line no-constant-condition
    (true ? `&segment=PERSONALCARE` : '') +
    (typeFilter ? `&type=${typeFilter}` : '') +
    (keyFilter ? `&key=${keyFilter}` : '');
  //
  const options = {
    method: 'GET',
    url: `${baseApi}/events/${event}/companies/${company}/products?operator=AND${queryParams}&sort=display_name,asc`,
    headers: { 'Content-Type': 'application/json' },
    params: {
      size: pageSize && pageSize.toString() !== '0' ? pageSize : '10',
      page: pageIndex
    }
  };

  request(options, handleSuccess, handleError);
};

export const loadAvailableProducts = (
  event: string,
  company: string,
  filters: any,
  handleSuccess: Function,
  handleError?: Function
) => {
  const search = filters?.search ? encodeURIComponent(filters?.search) : '';
  const pageSize = filters?.pageSize !== '0 ' ? filters?.pageSize : '10';
  const pageIndex = filters?.pageIndex ?? '0';
  //whitelabel
  const segmentFilter = `&segment=PERSONALCARE`;
  // let segmentFilter =
  //   filters?.segmentFilter && filters.segmentFilter !== 'ALL'
  //     ? `&segment=${filters?.segmentFilter}`
  //     : '';
  const typeFilter =
    filters?.typeFilter && filters.typeFilter !== 'ALL'
      ? `&type=${filters?.typeFilter}`
      : '';

  const queryParams =
    Object.keys(filters).length > 0
      ? `&name__icontains=${search}&identifiers.cas__icontains=${search}&identifiers.inci__icontains=${search}`
      : '';
  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${company}/events/${event}/products/available?operator=OR${queryParams}${segmentFilter}${typeFilter}&sort=name,asc`,
    headers: { 'Content-Type': 'application/json' },
    params: { size: pageSize, page: pageIndex }
  };
  request(options, handleSuccess, handleError);
};

export const loadEventProduct = (
  event: string,
  company: string,
  product: string,
  handleSuccess: Function,
  handleError: Function,
  toastOptions: any
) => {
  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${company}/events/${event}/products/${product}`,
    headers: { 'Content-Type': 'application/json' }
  };
  request(options, handleSuccess, handleError, toastOptions);
};

interface assignEventProductBody {
  all?: boolean;
  products?: { company: string; segment: string; product: string }[];
}

export const assignEventProduct = (
  body: assignEventProductBody,
  event: string,
  company: string,
  handleSuccess?: Function,
  handleError?: Function,
  toastOptions?: any
) => {
  const options = {
    method: 'POST',
    url: `${baseApi}/companies/${company}/events/${event}/products`,
    headers: { 'Content-Type': 'application/json' },
    data: body
  };

  request(options, handleSuccess, handleError, toastOptions);
};

export const updateEventProduct = (
  event: string,
  company: string,
  product: string,
  body: EventProduct,
  handleSuccess: Function,
  handleError: Function,
  toastOptions: any,
  signal?: AbortSignal
) => {
  const options = {
    method: 'PUT',
    url: `${baseApi}/companies/${company}/events/${event}/products/${product}`,
    headers: { 'Content-Type': 'application/json' },
    data: body
  };
  request(
    signal ? { ...options, signal } : options,
    handleSuccess,
    handleError,
    toastOptions
  );
};

export const disableEventProduct = (
  event: string,
  company: string,
  product: string,
  handleSuccess: Function,
  handleError?: Function,
  toastOptions?: any
) => {
  const options = {
    method: 'POST',
    url: `${baseApi}/companies/${company}/events/${event}/products/${product}/disable`,
    headers: { 'Content-Type': 'application/json' }
  };
  request(options, handleSuccess, handleError, toastOptions);
};
export const enableEventProduct = (
  event: string,
  company: string,
  product: string,
  handleSuccess: Function,
  handleError?: Function,
  toastOptions?: any
) => {
  const options = {
    method: 'POST',
    url: `${baseApi}/companies/${company}/events/${event}/products/${product}/enable`,
    headers: { 'Content-Type': 'application/json' }
  };
  request(options, handleSuccess, handleError, toastOptions);
};
export const promoteEventProduct = (
  event: string,
  company: string,
  product: string,
  handleSuccess: SuccessHandler,
  handleError: ErrorHandler | undefined,
  toastOptions?: ToastPromiseParams
) => {
  const options = {
    method: 'POST',
    url: `${baseApi}/companies/${company}/events/${event}/products/${product}/promote`,
    headers: { 'Content-Type': 'application/json' }
  };
  requestTS(options, handleSuccess, handleError, toastOptions);
};

export const demoteEventProduct = (
  event: string,
  company: string,
  product: string,
  handleSuccess: SuccessHandler,
  handleError: ErrorHandler,
  toastOptions?: ToastPromiseParams
) => {
  const options = {
    method: 'POST',
    url: `${baseApi}/companies/${company}/events/${event}/products/${product}/demote`,
    headers: { 'Content-Type': 'application/json' }
  };
  requestTS(options, handleSuccess, handleError, toastOptions);
};

export const addCompanyToEvent = (
  body: any,
  event: string | undefined,
  handleSuccess?: Function,
  handleError?: Function,
  toastOptions?: any
) => {
  const options = {
    method: 'POST',
    url: `${baseApi}/events/${event}/companies/enable`,
    headers: { 'Content-Type': 'application/json' },
    data: body
  };

  request(options, handleSuccess, handleError, toastOptions);
};
export const getEventProductLinked = (
  event: string,
  company: string,
  product: string,
  linkedType: ProductType.FORMULATIONS | ProductType.INGREDIENTS,
  handleSuccess: SuccessHandler,
  handleError: ErrorHandler
) => {
  const options = {
    method: 'GET',
    url: `${baseApi}/events/${event}/companies/${company}/products/${product}/${linkedType.toLowerCase()}/lite`,
    headers: { 'Content-Type': 'application/json' }
  };
  requestTS(options, handleSuccess, handleError);
};

export const disableCompanyFromEvent = (
  event: string | undefined,
  company: string,
  handleSuccess?: Function,
  handleError?: Function,
  toastOptions?: any
) => {
  const options = {
    method: 'POST',
    url: `${baseApi}/events/${event}/companies/${company}/disable`,
    headers: { 'Content-Type': 'application/json' }
  };

  request(options, handleSuccess, handleError, toastOptions);
};
export const enableCompanyFromEvent = (
  event: string | undefined,
  company: string,
  handleSuccess?: Function,
  handleError?: Function,
  toastOptions?: any
) => {
  const options = {
    method: 'POST',
    url: `${baseApi}/events/${event}/companies/${company}/enable`,
    headers: { 'Content-Type': 'application/json' }
  };

  request(options, handleSuccess, handleError, toastOptions);
};

//Images

export const updateEventImage = (
  event: string,
  section: string,
  content: any,
  handleSuccess?: Function,
  handleError?: Function,
  toastOptions: any = { pending: 'Uploading image' }
) => {
  const formData = new FormData();
  formData.append('content', content);

  const options = {
    method: 'POST',
    url: `${baseApi}/events/${event}/image`,
    headers: { 'Content-Type': 'multipart/form-data' },
    params: {
      section: section
    },
    data: formData
  };

  request(options, handleSuccess, handleError, toastOptions);
};
export const updateEventCompanyImage = (
  event: string,
  company: string,
  section: string,
  content: any,
  handleSuccess?: Function,
  handleError?: Function,
  toastOptions: any = { pending: 'Uploading image' }
) => {
  const formData = new FormData();
  formData.append('content', content);

  const options = {
    method: 'POST',
    url: `${baseApi}/companies/${company}/events/${event}/image`,
    headers: { 'Content-Type': 'multipart/form-data' },
    params: {
      section: section
    },
    data: formData
  };

  request(options, handleSuccess, handleError, toastOptions);
};

//Assets - Companies

export const loadEventCompanyAssets = (
  key: string,
  companyKey: string,
  handleSuccess: Function,
  handleError?: Function
) => {
  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${companyKey}/events/${key}/assets`,
    headers: { 'Content-Type': 'application/json' }
  };

  request(options, handleSuccess, handleError);
};

export const downloadEventCompanyAsset = (
  key: string,
  companyKey: string,
  asset: Asset,
  handleSuccess: Function,
  handleError?: Function
) => {
  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${companyKey}/events/${key}/assets/${asset.key}/download`,
    responseType: 'blob'
  };

  request(options, handleSuccess, handleError);
};

export const deleteEventCompanyAsset = (
  key: string,
  companyKey: string,
  asset: Asset,
  success: Function,
  handleError?: Function
) => {
  const options = {
    method: 'DELETE',
    url: `${baseApi}/companies/${companyKey}/events/${key}/assets/${asset.key}`
  };

  request(options, success, handleError);
};

export const uploadEventCompanyAsset = (
  key: string,
  companyKey: string,
  asset: Asset,
  file: File,
  handleSuccess: Function,
  handleError?: Function
) => {
  const data = new FormData();
  data.append(
    'assetdata',
    new Blob([JSON.stringify({ ...asset, access_policy: 'PUBLIC' })], {
      type: 'application/json'
    })
  );
  data.append('content', file);

  const options = {
    method: 'POST',
    url: `${baseApi}/companies/${companyKey}/events/${key}/assets`,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: data
  };
  request(options, handleSuccess, handleError);
};

//Assets - Products

export const loadEventProductAssets = (
  eventKey: string,
  companyKey: string,
  productKey: string,
  handleSuccess: Function,
  handleError?: Function
) => {
  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${companyKey}/events/${eventKey}/products/${productKey}/assets`,
    headers: { 'Content-Type': 'application/json' }
  };

  request(options, handleSuccess, handleError);
};

export const downloadEventProductAsset = (
  eventKey: string,
  companyKey: string,
  productKey: string,
  asset: Asset,
  handleSuccess: Function,
  handleError?: Function
) => {
  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${companyKey}/events/${eventKey}/products/${productKey}/assets/${asset.key}/download`,
    responseType: 'blob'
  };

  request(options, handleSuccess, handleError);
};

export const deleteEventProductAsset = (
  eventKey: string,
  companyKey: string,
  productKey: string,
  asset: Asset,
  success: Function,
  handleError?: Function
) => {
  const options = {
    method: 'DELETE',
    url: `${baseApi}/companies/${companyKey}/events/${eventKey}/products/${productKey}/assets/${asset.key}`
  };

  request(options, success, handleError);
};

export const uploadEventProductAsset = (
  eventKey: string,
  companyKey: string,
  productKey: string,
  asset: Asset,
  file: File,
  handleSuccess: Function,
  handleError?: Function
) => {
  const data = new FormData();
  data.append(
    'assetdata',
    new Blob([JSON.stringify({ ...asset })], {
      type: 'application/json'
    })
  );
  data.append('content', file);

  const options = {
    method: 'POST',
    url: `${baseApi}/events/${eventKey}/companies/${companyKey}/products/${productKey}/assets`,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: data
  };
  request(options, handleSuccess, handleError);
};

export const useGetBrandPortalSettings = () =>
  useAxiosWithAuth<{
    content: {
      event: { key: string; name: string; type: PortalTypes };
      permissions: {
        [key in BrandPortalPermissions]?: EventPermissionLevel;
      };
    }[];
  }>(
    {
      url: `${baseApi}/events/users/active`,
      method: 'GET'
    },
    { manual: true }
  );

export const uploadEventProductList = (
  key: string,
  file: File,
  handleSuccess: (result: any) => void,
  handleError: () => void
) => {
  const data = new FormData();

  data.append('file', file);

  const options = {
    method: 'POST',
    url: `${baseApi}/sync/events/${key}/products`,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: data
  };
  requestTS(options, handleSuccess, handleError);
};

export const editEventProductRanking = (
  eventKey: string,
  company: string,
  productType: ProductType,
  eventProductKeys: string[],
  handleSuccess: (result: any) => void,
  handleError?: () => void
) => {
  const options = {
    method: 'POST',
    url: `${baseApi}/events/${eventKey}/companies/${company}/products/${productType}/ranking`,
    headers: { 'Content-Type': 'application/json' },
    data: { products: eventProductKeys }
  };
  requestTS(options, handleSuccess, handleError);
};

type EventUserPatchInput = {
  user_type?: EventUserType | null;
  status?: UserStatus | null;
  admin_notes?: string | null;
};

type EventUserPatchResponse = {};

export const useEventUserPatch = (eventKey: string, userKey: string) =>
  useAxiosWithAuth<EventUserPatchResponse, EventUserPatchInput>(
    {
      url: `${baseApi}/events/${eventKey}/users/${userKey}`,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      }
    },
    {
      manual: true
    }
  );
