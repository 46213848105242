import { REGISTER_ROUTE } from 'layouts/Layout';

let hubspotInjected = false;
export let hubspotLoaded = false;

export const initHubspot = () => {
  if (
    // For testing purposes, we don't want to inject the Hubspot script sometimes
    window.location.search.includes('no-hubspot')
  ) {
    return;
  } else if (
    // We need to inject the script on the register page directly to ensure the form is sent to Hubspot
    window.location.pathname.includes(REGISTER_ROUTE)
  ) {
    injectHubspotScript();
  } else {
    // On all other pages the script gets injected when the user scrolls or after 5 seconds
    // We assume that the Google crawler does not scroll.
    window.addEventListener('scroll', () => injectHubspotScript(), {
      once: true
    });
    setTimeout(injectHubspotScript, 5000);
  }
};

const injectHubspotScript = () => {
  if (!hubspotInjected) {
    const hubspotUrl = process.env.REACT_APP_HUBSPOT_URL;
    if (hubspotUrl) {
      const script = document.createElement('script');
      script.src = hubspotUrl;
      script.async = true;
      script.defer = true;
      script.id = 'hs-script-loader';
      script.type = 'text/javascript';
      script.onload = () => {
        setTimeout(() => {
          hubspotLoaded = true;
        }, 2000);
      };
      document.body.appendChild(script);
    }
    hubspotInjected = true;
  }
};
