import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Zoom
} from '@mui/material';
import Logo from 'components/common/Logo';
import React, { useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useLocation } from 'react-router-dom';
import { tss } from 'tss-react/mui';
import { MobileProfileIcon } from './MobileProfileIcon';
import { UserDropDown } from './UserDropDown';
import { AdminDropdownMenu } from './AdminDropownMenu';
import { SingleNavBarItem } from './SingleNavBarItem';
import NotificationDropdown from './NotificationDropdown';
import { InviteModal } from './InviteModal';
import { Link } from 'react-router-dom';
import { NAVBAR_ID } from 'components/constants/Constants';

import { useNavigationBarRoles } from './useNavigationBarRoles';
import { Trans } from 'react-i18next';
import { ProductsDropdownMenu } from './ProductsDrowdown';
import { useIsRegisterPath } from './useIsRegisterPath';

const NavigationBar: React.FC<{ withoutMargins?: boolean }> = props => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const [showInviteModal, setShowInviteModal] = useState(false);
  const isRegisterPath = useIsRegisterPath();

  const navOpen = Boolean(anchorElNav);
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const { classes } = useStyles({
    withoutMargins: props.withoutMargins
  });
  const auth = useAuth();
  const {
    canEditProductsAndCompany,
    canInviteUsers,
    isBrandPortalAdmin,
    hasAnalyticsPermissions,
    hasLeadsPermissions,
    hasProjectSpacePermissions,
    hasEventsSectionPermissions,
    brandPortalRoutes,
    isSupportUser
  } = useNavigationBarRoles();

  const activeLink = useLocation().pathname;

  const startMenuItems = [
    ...(hasAnalyticsPermissions
      ? [{ label: 'Analytics', path: '/analytics' }]
      : []),
    ...(hasLeadsPermissions ? [{ label: 'Leads', path: '/leads' }] : []),
    {
      label: 'Ingredients',
      path: `/ingredients/search`
    },
    {
      label: 'Formulations',
      path: `/formulations/search`
    },
    {
      label: 'Services',
      path: `/services/search`
    },
    ...(hasProjectSpacePermissions
      ? [{ label: 'Projects', path: '/projects' }]
      : []),
    ...(auth.isAuthenticated
      ? [{ label: 'Contact Center', path: '/contacts' }]
      : []),
    ...(hasEventsSectionPermissions
      ? [{ label: 'Events', path: '/events' }]
      : [])
  ];
  const trailMenuItems = [
    {
      label: 'Buyers',
      path: `/buyers`
    },
    {
      label: 'Suppliers',
      path: `/suppliers`
    },
    {
      label: 'Blog',
      path: `https://blog.covalo.com/personal-care`
    }
  ];

  return (
    <AppBar className={classes.root} elevation={2} id={NAVBAR_ID}>
      <Toolbar className={classes.toolbar}>
        <Box className={classes.navBarLeftsSide}>
          <Logo
            style={{ marginBottom: 6, marginRight: '0.5em' }}
            variant="small"
          />
          <Box sx={{ display: { xs: 'flex', lg: 'none' } }}>
            <IconButton
              onClick={handleOpenNavMenu}
              aria-label="Open navigation menu"
            >
              <MenuIcon />
            </IconButton>
            {anchorElNav && (
              <Menu
                anchorEl={anchorElNav}
                open={navOpen}
                onClose={handleCloseNavMenu}
                TransitionComponent={Zoom}
              >
                {!auth.isAuthenticated && (
                  <ProductsDropdownMenu
                    closeOuterMenu={() => handleCloseNavMenu()}
                  />
                )}
                {isBrandPortalAdmin &&
                  !isSupportUser &&
                  !canEditProductsAndCompany && (
                    <SingleNavBarItem
                      type="menuitem"
                      handleMenuClose={handleCloseNavMenu}
                      item={{
                        path: `/admin/event/${brandPortalRoutes?.[0].key}/dashboard`,
                        label: `Admin`
                      }}
                      activeLink={activeLink}
                    />
                  )}
                {(canEditProductsAndCompany || isSupportUser) && (
                  <AdminDropdownMenu
                    closeOuterMenu={() => handleCloseNavMenu()}
                    activeLink={activeLink}
                    brandPortalRoutes={brandPortalRoutes}
                  />
                )}

                {startMenuItems.map(item => (
                  <SingleNavBarItem
                    type="menuitem"
                    key={item.label}
                    handleMenuClose={handleCloseNavMenu}
                    item={item}
                    activeLink={activeLink}
                  />
                ))}
                {canInviteUsers && (
                  <MenuItem
                    sx={{ display: { xs: 'block', sm: 'none' } }}
                    onClick={() => {
                      setShowInviteModal(true);
                      handleCloseNavMenu();
                    }}
                  >
                    Invite
                  </MenuItem>
                )}
              </Menu>
            )}
          </Box>

          <Box
            sx={theme => ({
              display: { xs: 'none', lg: 'flex' },
              gap: theme.spacing(1)
            })}
          >
            {!auth.isAuthenticated && (
              <ProductsDropdownMenu
                closeOuterMenu={() => handleCloseNavMenu()}
              />
            )}
            {isBrandPortalAdmin &&
              !isSupportUser &&
              !canEditProductsAndCompany && (
                <SingleNavBarItem
                  type="button"
                  handleMenuClose={handleCloseNavMenu}
                  item={{
                    path: `/admin/event/${brandPortalRoutes?.[0].key}/dashboard`,
                    label: `Admin`
                  }}
                  key={brandPortalRoutes?.[0].label}
                  activeLink={activeLink}
                />
              )}
            {(canEditProductsAndCompany || isSupportUser) && (
              <AdminDropdownMenu
                activeLink={activeLink}
                brandPortalRoutes={brandPortalRoutes}
              />
            )}
            {startMenuItems.map(item => (
              <SingleNavBarItem
                type="button"
                key={item.label}
                item={item}
                activeLink={activeLink}
              />
            ))}
          </Box>
        </Box>
        <Box className={classes.navBarRightSide}>
          {!auth.isAuthenticated ? (
            <>
              <Box
                sx={theme => ({
                  display: { xs: 'none', lg: 'flex' },
                  gap: theme.spacing(1)
                })}
              >
                {trailMenuItems.map(item => (
                  <SingleNavBarItem
                    key={item.label}
                    type="button"
                    item={item}
                    activeLink={activeLink}
                  />
                ))}
                {!isRegisterPath && (
                  <Button
                    variant="contained"
                    sx={{ textTransform: 'capitalize' }}
                    component={Link}
                    to="/register"
                  >
                    <Trans i18nKey="auth.signup" context="free" />
                  </Button>
                )}
                <Button
                  variant="outlined"
                  onClick={() =>
                    auth.signinRedirect({
                      redirect_uri:
                        window.location.origin + window.location.pathname
                    })
                  }
                >
                  <Trans i18nKey="auth.login" />
                </Button>
              </Box>
            </>
          ) : (
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center'
              }}
            >
              <SingleNavBarItem
                type="button"
                item={{
                  label: 'Blog',
                  path: `https://blog.covalo.com/personal-care`
                }}
                activeLink={activeLink}
              />
              {canInviteUsers && (
                <Button
                  sx={{
                    display: { xs: 'none', sm: 'block' },
                    textTransform: 'capitalize'
                  }}
                  variant="outlined"
                  onClick={() => setShowInviteModal(true)}
                >
                  Invite
                </Button>
              )}
              <Box>
                <NotificationDropdown />
                <UserDropDown />
              </Box>
            </Box>
          )}
          {!auth.isAuthenticated && <MobileProfileIcon />}
        </Box>
      </Toolbar>
      <InviteModal show={showInviteModal} onHide={setShowInviteModal} />
    </AppBar>
  );
};
export default NavigationBar;

const useStyles = tss
  .withParams<{ withoutMargins?: boolean }>()
  .create(({ theme, withoutMargins }) => ({
    root: {
      background: 'white',
      zIndex: 1055, // TODO fix modals,
      width: '100vw',
      marginLeft: withoutMargins ? undefined : '-1rem',
      marginBottom: withoutMargins ? undefined : '1.5rem',
      position: 'sticky'
    },
    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    navBarLeftsSide: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 'auto',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row-reverse',
        gap: theme.spacing(1)
      }
    },
    navBarRightSide: {
      display: 'flex',
      alignItems: 'center'
    }
  }));
