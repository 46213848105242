import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';

const PublicRoute: React.FC<{
  component: JSX.Element;
  unauthorizedOnly?: boolean;
  hasWhiteBackground?: boolean;
  noVerticalPadding?: boolean;
  disableTopMargin?: boolean;
  noHorizontalPadding?: boolean;
  preventDoubleScrollbars?: boolean;
}> = ({
  component,
  unauthorizedOnly,
  hasWhiteBackground,
  noVerticalPadding,
  disableTopMargin,
  noHorizontalPadding,
  ...rest
}) => {
  if (unauthorizedOnly) return <Navigate to="/errors/unauthorized-only" />;
  return (
    <MainLayout
      noVerticalPadding={noVerticalPadding}
      component={component}
      hasWhiteBackground={hasWhiteBackground}
      disableTopMargin={disableTopMargin}
      noHorizontalPadding={noHorizontalPadding}
      {...rest}
    />
  );
};

export default PublicRoute;
PublicRoute.propTypes = {
  component: PropTypes.any
};
