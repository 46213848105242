import { Button, MenuItem } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  item: { path: string; label: string };
  type: 'button' | 'menuitem';
  activeLink: string;
  handleMenuClose?: () => void;
}

export const SingleNavBarItem: React.FC<Props> = ({
  item,
  activeLink,
  type,
  handleMenuClose
}) => {
  return (type as 'menuitem' | 'button') === 'menuitem' ? (
    <MenuItem
      component={Link}
      to={item.path}
      onClick={handleMenuClose}
      sx={theme => ({
        color:
          item.path === activeLink ? theme.palette.primary.main : 'default',
        textTransform: 'none',
        [`&:hover,active`]: {
          background: 'inherit',
          color: theme.palette.primary.main
        }
      })}
    >
      {item.label}
    </MenuItem>
  ) : (
    <Button
      variant="text"
      component={Link}
      to={item.path}
      sx={theme => ({
        textTransform: 'capitalize',
        fontWeight: 400,
        color:
          item.path === activeLink
            ? theme.palette.primary.main
            : theme.palette.text.primary
      })}
    >
      {item.label}
    </Button>
  );
};
