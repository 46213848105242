import { Link } from 'react-router-dom';
import CovaloLogo from 'assets/img/logos/CovaloLogo';
import { useAuth } from 'react-oidc-context';

const Logo: React.FC<{
  style: React.CSSProperties;
  variant: 'small' | 'legacy';
}> = ({ style, variant }) => {
  const { isAuthenticated } = useAuth();
  return (
    <Link
      style={style}
      to={isAuthenticated ? '/ingredients/search' : '/'}
      aria-label={isAuthenticated ? 'Go to search page' : 'Go to home page'}
    >
      <CovaloLogo variant={variant} />
    </Link>
  );
};

export default Logo;
