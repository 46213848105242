import {
  request,
  requestRedirectDownload,
  requestUnauthenticated
} from '../api';
export const baseApi = process.env.REACT_APP_COVALO_API;
export const baseSearchApi = process.env.REACT_APP_COVALO_API_SEARCH;

export const create = (project, success, handleError) => {
  const options = {
    method: 'POST',
    url: `${baseApi}/companies/${project.company.key}/projects`,
    headers: { 'Content-Type': 'application/json' },
    data: project
  };

  request(options, success, handleError);
};

export const searchProductDetails = (key, success, handleError) => {
  const options = {
    method: 'GET',
    url: `${baseSearchApi}/search/document/legacy/${key}`,
    headers: { 'Content-Type': 'application/json' }
  };

  request(options, success, handleError);
};
export const searchProductDetailsUnAuthenticated = (
  key,
  success,
  handleError
) => {
  const options = {
    method: 'GET',
    url: `${baseSearchApi}/search/document/legacy/${key}`,
    headers: { 'Content-Type': 'application/json' }
  };

  requestUnauthenticated(options, success, handleError);
};

export const update = (project, success, handleError) => {
  const options = {
    method: 'PUT',
    url: `${baseApi}/companies/${project.company.key}/projects/${project.key}`,
    headers: { 'Content-Type': 'application/json' },
    data: project
  };

  request(options, success, handleError);
};

export const findProjects = (
  search,
  company,
  category,
  success,
  page = 0,
  handleError
) => {
  const defaultParams = `operator=OR&title__icontains=${search}&subtitle__icontains=${search}`;

  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${company}/projects?${defaultParams}`,
    headers: { 'Content-Type': 'application/json' },
    params: {
      size: 100,
      page: page
    }
  };

  request(options, success, handleError);
};

export const findProject = (company, key, success, handleError) => {
  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${company}/projects/${key}`,
    headers: { 'Content-Type': 'application/json' }
  };

  request(options, success, handleError);
};

// /companies/{company}/projects/{path}

export const findCompanyUsers = (company, success, handleError) => {
  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${company}/users`,
    headers: { 'Content-Type': 'application/json' },
    params: {
      size: 300
    }
  };

  request(
    options,
    response => {
      const users = [];
      response.data.content.forEach(item => {
        users.push({
          value: item.key,
          label: item.firstname + ' ' + item.lastname
        });
      });
      success(users);
    },
    handleError
  );
};

export const findProjectAvailableUsers = (
  company,
  project,
  success,
  handleError
) => {
  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${company}/projects/${project}/available-users`,
    headers: { 'Content-Type': 'application/json' }
  };

  request(
    options,
    response => {
      const users = [];
      response.data.content.forEach(item => {
        users.push({
          value: item.key,
          label: item.firstname + ' ' + item.lastname
        });
      });
      success(users);
    },
    handleError
  );
};

export const addNewProjectMember = (project, member, success, handleError) => {
  const options = {
    method: 'POST',
    url: `${baseApi}/companies/${project.company.key}/projects/${project.key}/member`,
    headers: { 'Content-Type': 'application/json' },
    data: member
  };

  request(options, success, handleError);
};

export const getProjectMember = (project, success, handleError) => {
  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${project.company.key}/projects/${project.key}/member`,
    headers: { 'Content-Type': 'application/json' }
  };

  request(options, success, handleError);
};

export const deleteMember = (project, member, handleError) => {
  const options = {
    method: 'DELETE',
    url: `${baseApi}/companies/${project.company.key}/projects/${project.key}/member/${member.key}`,
    headers: { 'Content-Type': 'application/json' },
    data: member
  };

  request(options, handleError);
};

export const toggleBlockUser = (project, member, userStatus, handleError) => {
  const options = {
    method: 'POST',
    url: `${baseApi}/companies/${project.company.key}/projects/${project.key}/member/${member.key}/${userStatus}`,
    headers: { 'Content-Type': 'application/json' },
    data: member
  };

  request(options, handleError);
};

export const findProjectProducts = (project, success, handleError) => {
  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${project.company.key}/projects/${project.key}/products`,
    headers: { 'Content-Type': 'application/json' },
    params: {
      size: 100
    }
  };
  request(options, success, handleError);
};

export const findProjectProductsNew = (
  project,
  searchFilterParams,
  success,
  handleError
) => {
  const { company, search, status, type } = searchFilterParams;
  const defaultParams = '&sort=name,asc';
  const filters = `operator=AND${type && `&product.type__icontains=${type}`}${
    company && `&company.key__icontains=${company}`
  }&product.name__icontains=${search}${
    status && `&status__icontains=${status.toUpperCase()}`
  }`;
  let sorted = '';
  searchFilterParams?.sort?.map(item => {
    sorted = `${sorted}&sort=${item.id},${item.desc ? 'desc' : 'asc'}`;
  });
  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${project.company.key}/projects/${project.key}/products?${filters}${sorted}`,
    headers: { 'Content-Type': 'application/json' },
    params: {
      size: searchFilterParams.size,
      page: searchFilterParams.page
    }
  };
  request(options, success, handleError);
};

export const findProjectProductsDocuments = (
  project,
  params,
  success,
  handleError
) => {
  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${project.company.key}/projects/${project.key}/products/documents`,
    headers: { 'Content-Type': 'application/json' },
    params: {
      ...params
    }
  };
  request(options, success, handleError);
};

// To accept or reject a project product that is to review
export const setProductState = (
  project,
  product,
  action,
  success,
  handleError
) => {
  const options = {
    method: 'POST',
    url: `${baseApi}/companies/${project.company.key}/projects/${project.key}/products/${product.key}/${action}`,
    headers: { 'Content-Type': 'application/json' },
    data: { message: product.message, tags: product.tags }
  };

  request(options, success, handleError);
};

export const addNewProjectProduct = (
  project,
  product,
  success,
  handleError
) => {
  const newProductData = {
    company: product.supplier_key,
    segment: product.segment,
    product: product.key
  };
  const options = {
    method: 'POST',
    url: `${baseApi}/companies/${project.company.key}/projects/${project.key}/products`,
    headers: { 'Content-Type': 'application/json' },
    data: newProductData
  };

  request(options, success, handleError);
};

//From search
export const addNewProjectProductFromSearch = (
  companyKey,
  projectKey,
  product,
  handleSuccess,
  handleError
) => {
  const newProductData = {
    company: product.company.key,
    segment: product.segment,
    product: product.key
  };
  const options = {
    method: 'POST',
    url: `${baseApi}/companies/${companyKey}/projects/${projectKey}/products`,
    headers: { 'Content-Type': 'application/json' },
    data: newProductData
  };
  const toastOptions = {
    pending: `Adding ${product.name} to you project`,
    success: 'Product added successfully'
  };

  request(options, handleSuccess, handleError, toastOptions);
};

export const deleteProjectProduct = (project, product, handleError) => {
  if (product?.key) {
    const options = {
      method: 'DELETE',
      url: `${baseApi}/companies/${project.company.key}/projects/${project.key}/products/${product.key}`,
      headers: { 'Content-Type': 'application/json' }
    };

    request(options, handleError);
  }
};
export const updateProjectProduct = (project, product, handleError) => {
  const options = {
    method: 'PUT',
    url: `${baseApi}/companies/${project.company.key}/projects/${project.key}/products/${product.key}`,
    headers: { 'Content-Type': 'application/json' },
    data: product
  };

  request(options, handleError);
};

export const UploadProjectImage = (
  companyKey,
  projectKey,
  content,
  success,
  handleError
) => {
  const formData = new FormData();
  formData.append('content', content);

  const options = {
    method: 'POST',
    url: `${baseApi}/companies/${companyKey}/projects/${projectKey}/image`,
    headers: { 'Content-Type': 'multipart/form-data' },
    // params: {
    //   section: section
    // },
    data: formData
  };

  request(options, success, handleError);
};

export const deleteProjectImage = (
  companyKey,
  projectKey,
  reference,
  success,
  error
) => {
  const options = {
    method: 'DELETE',
    url: `${baseApi}/companies/${companyKey}/projects/${projectKey}/image/${reference}`
  };

  request(options, success, error);
};

export const createContactRequest = (
  body,
  companyKey,
  projectKey,
  success,
  error
) => {
  const options = {
    method: 'POST',
    url: `${baseApi}/companies/${companyKey}/projects/${projectKey}/contacts`,
    data: body
  };

  request(options, success, error);
};

export const getDownloadUrl = (company, project, download, error) => {
  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${company}/projects/${project}/products/documents/downloads/${download}/url`
  };

  requestRedirectDownload(options, error);
};

export const createDownloadUrl = (company, project, params, success, error) => {
  const options = {
    method: 'POST',
    url: `${baseApi}/companies/${company}/projects/${project}/products/documents/downloads`,
    params: {
      ...params
    }
  };

  request(options, success, error);
};

export const findProjectStats = (project, success, error) => {
  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${project.company.key}/projects/${project.key}/stats`
  };
  request(options, success, error);
};

export const getListedCompanies = (
  project,
  productType,
  company,
  success,
  handleError
) => {
  let query = `?product_type=${productType.toUpperCase()}`;
  if (company) {
    query += '&filter=' + company;
  }
  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${project.company.key}/projects/${project.key}/products/listed-companies${query}`
    // headers: { 'Content-Type': 'application/json' }
  };

  request(options, success, handleError);
};

export const findAvailableProjects = (
  userCompanyKey,
  product,
  handleSuccess,
  handleError
) => {
  const options = {
    method: 'GET',
    url: `${baseApi}/companies/${userCompanyKey}/projects/available?product=${product.key}&company=${product.company.key}&segment=${product.segment}`,
    headers: { 'Content-Type': 'application/json' }
  };
  request(options, handleSuccess, handleError);
};
