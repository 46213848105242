import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

/**
 * We need this to sign in the user if he loads the page but the session is expired.
 *
 * The OIDC library stores the session in the localStorage. In case of inactivity
 * for about 15 minutes (no tab open anymore, sleeping computer, cut network), the
 * session expires.
 *
 * Therefore we do a silent signin to refresh the session. During the silent signin, the library
 * checks with the keycloak server if the session can be restored. This can be done by using the
 * KEYCLOAK_IDENTITIY cookie, which lives 7 days in case the user checked "remember me" during login.
 *
 * But that's interna of keycloak, we only need to know that a silent signin is necesarry to restore
 * expired sessions.
 */
export const ExpiredSessionHelper: React.FC = () => {
  const { events, signinSilent } = useAuth();

  useEffect(() => {
    events.addAccessTokenExpired(() => {
      // eslint-disable-next-line no-console
      console.info(
        'Access token expired, trying to sign in silently to restore the session...'
      );
      signinSilent();
    });
  }, [events, signinSilent]);

  return null;
};
