import React, { useContext } from 'react';

const DropdownContext = React.createContext({
  dropdownOpen: false,
  setDropdownOpen: (open: boolean) => {}
});

export const useDropdown = () => useContext(DropdownContext);

export const DropdownProvider: React.FC<React.PropsWithChildren> = ({
  children
}) => {
  const [open, setOpen] = React.useState(false);
  const value = React.useMemo(
    () => ({ dropdownOpen: open, setDropdownOpen: setOpen }),
    [open]
  );

  return (
    <DropdownContext.Provider value={value}>
      {children}
    </DropdownContext.Provider>
  );
};
