import { SVGProps } from 'react';

export const NotFoundLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 99 94"
      fill="none"
      {...props}
    >
      <rect
        width={31.811}
        height={5.926}
        x={22.157}
        y={0.389}
        stroke={props.color}
        strokeWidth={1.952}
        rx={2.963}
        transform="rotate(30 22.157 .39)"
      />
      <path
        stroke={props.color}
        strokeWidth={1.952}
        d="M24.325 8.484 41.881 18.62 29.406 40.227l-5.246-1.049-2.83-5.234-6.65 1.382-2.83-5.235L24.326 8.484ZM40.343 78.806c1.696 5.334-1.253 11.034-6.588 12.73-5.334 1.697-11.034-1.253-12.73-6.587l-8.317-26.155 5.444.397 3.317-6.291 5.917 1.882 3.554-5.548 9.403 29.572Z"
      />
      <path
        fill="currentColor"
        stroke={props.color}
        strokeWidth={1.952}
        d="M36.984 79.172a6.499 6.499 0 0 1-12.387 3.939L20.031 68.75l3.449.124 2.229-3.714 3.72.977 2.365-3.288 5.19 16.323Z"
      />
      <path
        fill="currentColor"
        d="m3.357 53.494 3.38 2.648 1.667-6.159-5.047 3.51ZM2.19 35.927l3.87 4.038 1.841-4.731-5.711.693ZM1.117 48.555 6.18 44.8.573 43.471l.544 5.084ZM75 73a24 24 0 1 0 0-48 24 24 0 0 0 0 48Zm0-46a22 22 0 1 1 0 44 22 22 0 0 1 0-44Z"
      />
      <path
        fill="currentColor"
        d="M65.72 59.72 75 50.44l9.28 9.28a1.018 1.018 0 1 0 1.44-1.44L76.44 49l9.28-9.28a1.018 1.018 0 1 0-1.44-1.44L75 47.56l-9.28-9.28a1.018 1.018 0 0 0-1.44 1.44L73.56 49l-9.28 9.28a1.019 1.019 0 0 0 1.44 1.44Z"
      />
    </svg>
  );
};
